import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import Header from '../components/header'
import BackgroundImage from 'gatsby-background-image'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import NavMobile from "../components/nav_mobile"

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <NavMobile />
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <Header />
            <BackgroundImage className="layout title__48 banner__background" fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__container">
                    <div className="layout__title banner center">
                        <h1>{entry.frontmatter.heading}</h1>
                    </div>
                </div>
            </BackgroundImage>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb breadcrumb__top">
                            <p><Link to="/">Home</Link> &nbsp;&rsaquo;&nbsp; <strong>Blog</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="body flex flex__space-around">
                            <div className="block__60 text">
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                {data.allMarkdownRemark.edges.map(({ node }) => {
                                    return (
                                        <div className="blog__list" key={node.id}>
                                            <h3><Link to={node.fields.slug}>{node.frontmatter.heading} &rsaquo;</Link></h3>
                                            <p>{node.excerpt}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    )
}

export const query = graphql`
    query($slug: String!) {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC } filter: {frontmatter: {section: {eq: "blog"}}}) {
            edges {
                node {
                    id
                    frontmatter {
                        date(formatString: "DD MMMM, YYYY")
                        heading
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
        markdownRemark( fields: { slug: { eq: $slug } }) {
            frontmatter {
            title
            page_title
            meta_description
            heading
            image {
                childImageSharp {
                    fluid(maxWidth: 1024) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        fields {
            slug
        }
        html
        }
    }
`